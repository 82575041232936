import React from 'react';
import NewPage from '../common/NewPage';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import Helpline from './Helpline';
import StarryTitle from './StarryTitle';
import Brochure from './Brochure';
import { SocialMediaIcons } from '../SocialMediaIcons';
import { getDomainAddress } from '../../utils';
import '../../css/content-page-stylesheet.css';

function transformNode(node, index) {
  const { attribs, type, name, children } = node;

  if (
    attribs &&
    attribs.style &&
    (name === 'span' ||
      name === 'a' ||
      name === 'p' ||
      name === 'li' ||
      name === 'ul' ||
      name === 'h1' ||
      name === 'h2' ||
      name === 'h3' ||
      name === 'h4' ||
      name === 'h5' ||
      name === 'h6')
  ) {
    delete attribs.style;
  }

  if (name === 'img') {
    let imgStyles = 'max-width: 100%; height: auto;';
    const style = attribs.style;

    if (style) {
      imgStyles = `${imgStyles} ${style}`;
      attribs.style = imgStyles;
    } else {
      attribs.style = imgStyles;
    }

    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && children.length === 1) {
    if (children[0].data === ' ') {
      return null;
    }

    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && name !== 'br' && children.length === 0) {
    return null;
  }

  if (name === 'p' && node.children[0] && node.children[0].name === 'ul') {
    const transformedLis = node.children[0].children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul>{transformedLis}</ul>;
  }

  if (name === 'ul' && node.children[0]) {
    const transformedLis = node.children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul key={index + name}>{transformedLis}</ul>;
  }

  if (type === 'tag' && name === 'p') {
    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && name === 'span') {
    return convertNodeToElement(node, index, transformNode);
  }

  if (name === 'meta') {
    return null;
  }

  if (type === 'tag' && (name === 'h1' || name === 'h2')) {
    return (
      <Box key={index + name} pt={4} pb={4}>
        <StarryTitle text={node.children[0].data} />
      </Box>
    );
  }
}

const useStyles = makeStyles({
  content: {
    '& *': {
      wordBreak: 'break-word',
    },
    '& p, span, li': {
      fontSize: '1.5rem',
    },
    '& ul': {
      paddingLeft: 50,
    },
  },
});

const ContentPage = ({
  content,
  icon,
  iconAlt = '',
  hideContactInfo,
  pageSettings,
  showImageAndTitle = true,
  children,
  template = '',
}) => {
  const classes = useStyles();
  const schemaLdJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'PrimeFm',
    description: content['og_desc'],
    brand: pageSettings.brand,
    openingHours: pageSettings['business_hours'],
    image: icon,
    '@id': `${getDomainAddress()}`,
    url: `${window.location.href}`,
    telephone: pageSettings.phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: pageSettings.streetAddress,
      addressLocality: pageSettings.addressLocality,
      postalCode: pageSettings.postalCode,
      addressCountry: pageSettings.addressCountry,
    },
  };

  return (
    <NewPage
      showImageAndTitle={showImageAndTitle}
      icon={icon}
      iconAlt={iconAlt}
      pageTitle={content.title}
    >
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.metaDescription} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={content.title} />
        <meta property="og:description" content={content.metaDescription} />
        <meta property="og:url" content={`${window.location.href}`} />
        {icon && <meta property="og:image" content={icon} />}
        <meta
          property="business:contact_data:street_address"
          content={pageSettings.streetAddress}
        />
        <meta
          property="business:contact_data:locality"
          content={pageSettings.addressLocality}
        />
        <meta property="business:contact_data:region" content="Greater London" />
        <meta
          property="business:contact_data:postal_code"
          content={pageSettings.postalCode}
        />
        <meta
          property="business:contact_data:country_name"
          content={pageSettings.addressCountry}
        />
        <script type="application/ld+json">{JSON.stringify(schemaLdJson)}</script>
      </Helmet>
      <Box className={classes.content} pt={2} pb={2}>
        {ReactHtmlParser(content.content, { transform: transformNode })}
      </Box>
      {children}
      {template === 'service' && <Brochure />}
      {!hideContactInfo && (
        <>
          <Helpline email={pageSettings.email} phone={pageSettings.phone} />
          <SocialMediaIcons />
        </>
      )}
    </NewPage>
  );
};

ContentPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  content: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  hideContactInfo: PropTypes.bool,
  iconAlt: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  pageName: PropTypes.string,
  url: PropTypes.string,
  pageSettings: PropTypes.object,
  showImageAndTitle: PropTypes.bool,
  template: PropTypes.string,
};

export default ContentPage;
