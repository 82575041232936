import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../theme/theme';

const useStyles = makeStyles({
  icon: {
    '& img': {
      height: 120,
      width: 120,
      [theme.breakpoints.up('md')]: {
        height: 180,
        width: 180,
      },
    },
  },
});

const Centerpiece = ({ icon, iconAlt = '', title }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {icon && (
        <Box className={classes.icon}>
          <img src={icon} alt={iconAlt === '' ? 'page icon' : iconAlt} />
        </Box>
      )}
      <Box width="100%" pt={1}>
        <Typography variant="h1" align="center">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

Centerpiece.propTypes = {
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  title: PropTypes.string,
};

export default Centerpiece;
