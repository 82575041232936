import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ContactData } from './ContactData';
import { ContactForm } from './ContactForm';
import PropTypes from 'prop-types';
import { withContentPage } from '../../../HoC';

const useStyles = makeStyles({
  contactBox: {
    background: '#4E4E50',
    height: '100%',
    borderRadius: '24px',
  },
});

const ContactUsComponent = ({ pageSettings, children }) => {
  const { contactBox } = useStyles();
  return (
    <div style={{ width: '100%' }}>
      <Box className={contactBox}>
        <ContactData settings={pageSettings} />
        <ContactForm />
      </Box>
      {children}
    </div>
  );
};

ContactUsComponent.propTypes = {
  pageSettings: PropTypes.object,
  content: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const ContactUs = withContentPage(ContactUsComponent);

export { ContactUs };
