import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Footer from './components/common/Footer';
import Navbar from './components/Navbar';
import theme from './theme/theme';
import MainPage from './components/main/MainPage';
import Plans from './components/plans/Plans';
import Error from './components/Error';
import Loading from './components/Loading';
import ScrollToTop from './components/common/ScrollToTop';
import ContentPage from './components/common/ContentPage';
import VirusIcon from './images/virus-icon.svg';
import PlanDialog from './components/plans/components/PlanDialog';
import { AllPosts } from './pages/Posts';
import { BlogContext, PageSettingsContext } from './contexts';
import { Careers, ContactUs } from './pages';
import { useFetchData } from './hooks';
import { Post } from './pages';
import './App.css';

const SAMPLE_CONTENT = { title: 'page title', meta_desc: 'desc', text: 'text' };
const SAMPLE_PLANS = {
  text: 'text',
  section1: 'section1',
  section2: 'section2',
  section3: 'section3',
  helpline: 'helpline',
  modalContent: <p>sample content</p>,
  modalBottomText: '',
};

const App = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState({
    modalContent: SAMPLE_CONTENT.modalContent,
    modalBottomText: SAMPLE_CONTENT.modalBottomText,
  });

  const { blogRoute, setBlogRoute } = useContext(BlogContext);
  const { setPageSettings } = useContext(PageSettingsContext);

  const covidData = useFetchData('/covid');
  const pagesData = useFetchData('/pages');
  const reviewsData = useFetchData('/reviews');
  const pageSettings = useFetchData('/settings');

  useEffect(() => {
    const pages = pagesData.data?.pages;

    if (pages) {
      const blogPage = pages.find(({ template }) => template === 'blog');

      if (blogPage) {
        const { slug } = blogPage;
        const route = `/${slug}`;

        if (route !== blogRoute) {
          setBlogRoute(route);
        }
      }
    }
  }, [pagesData.isLoading === false && pagesData.data?.pages.length > 0]);

  useEffect(() => {
    const settings = pageSettings.data?.settings;

    if (settings) {
      setPageSettings(settings[0]);
    }
  }, [pageSettings.isLoading === false]);

  const isError = () => {
    return (
      pageSettings.error || reviewsData.error || pagesData.error || covidData.error
    );
  };

  const isLoading = () => {
    return (
      pageSettings.isLoading ||
      reviewsData.isLoading ||
      pagesData.isLoading ||
      covidData.isLoading
    );
  };

  const updateData = (modalContent, modalBottomText) => {
    setData({ modalContent, modalBottomText });
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const StandardContentPage = (props) => {
    return <ContentPage pageSettings={pageSettings.data?.settings[0]} {...props} />;
  };

  return (
    <>
      {isError() && <Error message="An error occurred. Please try again later." />}
      {isLoading() && <Loading />}
      {!isLoading() && !isError() && (
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box height="100%" className="App" position="relative">
              <Navbar phone={pageSettings.data['settings'][0].phone} />
              <Container maxWidth="xl">
                <ScrollToTop />
                <Switch>
                  <Route exact path="/">
                    <MainPage
                      mainPageData={pagesData.data['pages']}
                      reviewsData={reviewsData.data['reviews']}
                      pageSettings={pageSettings.data['settings'][0]}
                    />
                  </Route>
                  <Route path="/post/:id">
                    <Post />
                  </Route>
                  <Route path="/covid">
                    <Plans
                      plansData={
                        covidData.data['covid'][0].id
                          ? covidData.data['covid'][0]
                          : SAMPLE_PLANS
                      }
                      icon={
                        pagesData.data['pages'].length > 0
                          ? pagesData.data['pages'][0].attachment
                          : VirusIcon
                      }
                      pageSettings={pageSettings.data['settings'][0]}
                      handleOpen={handleOpen}
                      updateData={updateData}
                    />
                  </Route>

                  {pagesData.data['pages'].map(({ slug, template }, index) => {
                    if (template === 'blog') {
                      return;
                    }

                    return (
                      <Route key={slug} path={`/${slug}`}>
                        {template === 'contact' && (
                          <ContactUs
                            pageMeta={{
                              title: pagesData.data['pages'][index].title,
                              description: pagesData.data['pages'][index].excerpt,
                              icon: pagesData.data['pages'][index].attachment,
                              iconAlt: pagesData.data['pages'][index].attachment_alt,
                            }}
                          >
                            {pagesData.data['pages'][index].content}
                          </ContactUs>
                        )}
                        {template === 'careers' && (
                          <Careers
                            pageMeta={{
                              title: pagesData.data['pages'][index].title,
                              description: pagesData.data['pages'][index].excerpt,
                              icon: pagesData.data['pages'][index].attachment,
                              iconAlt: pagesData.data['pages'][index].attachment_alt,
                            }}
                          >
                            {pagesData.data['pages'][index].content}
                          </Careers>
                        )}
                        {slug === 'covid-19' && (
                          <Plans
                            plansData={
                              covidData.data['covid'][0].id
                                ? covidData.data['covid'][0]
                                : SAMPLE_PLANS
                            }
                            icon={
                              pagesData.data['pages'].length > 0
                                ? pagesData.data['pages'][0].attachment
                                : VirusIcon
                            }
                            pageSettings={pageSettings.data['settings'][0]}
                            content={pagesData.data['pages'][index].content}
                            handleOpen={handleOpen}
                            updateData={updateData}
                          />
                        )}
                        {(!template || template === 'service') &&
                          slug !== 'covid-19' && (
                            <StandardContentPage
                              icon={
                                pagesData.data['pages'][index]
                                  ? pagesData.data['pages'][index].attachment
                                  : VirusIcon
                              }
                              content={
                                pagesData.data['pages'][index]
                                  ? pagesData.data['pages'][index]
                                  : SAMPLE_CONTENT
                              }
                              template={template}
                            />
                          )}
                      </Route>
                    );
                  })}
                  <Route path={blogRoute}>
                    <AllPosts />
                  </Route>
                </Switch>

                <PlanDialog
                  handleClose={handleClose}
                  modalContent={data.modalContent}
                  modalBottomText={data.modalBottomText}
                  email={pageSettings.data['settings'].email}
                  phone={pageSettings.data['settings'].phone}
                  helpline={
                    covidData.data['covid'].id
                      ? covidData.data['covid'].helpline
                      : SAMPLE_PLANS.helpline
                  }
                  id={1}
                  open={dialogOpen}
                />
              </Container>
              <Footer pageSettings={pageSettings.data['settings'][0]} />
            </Box>
          </ThemeProvider>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
