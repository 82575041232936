import React from 'react';
import PropTypes from 'prop-types';
import { PostCard } from './PostCard';
import { makeStyles, Box } from '@material-ui/core';
import { ReactComponent as ScrollDown } from '../../../images/svg/scrollDown.svg';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingBottom: 80,
    display: 'grid',
    gridTemplate: '1fr / 1fr',
    gridGap: '30px 20px',
    width: 'initial',
    [theme.breakpoints.up('sm')]: {
      gridTemplate: '1fr 1fr /1fr 1fr',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplate: '1fr /1fr 1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplate: '1fr /1fr 1fr 1fr',
    },
    [theme.breakpoints.up('Xl')]: {
      gridTemplate: '1fr 1fr /auto auto auto 1fr',
    },
  },
  headerBox: {
    textAlign: 'center',
  },
  scrollDownIcon: {
    marginBottom: '40px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  headerStyle: {
    padding: '100px 0 10px 0',
  },
   loadMoreButton: {
    color: '#FFFFFF',
    width: '150px',
    border: '1px solid rgba(201, 148, 255, 0.5)',
    height: '40px',
    fontSize: '12px',
    borderRadius: '12px',
    letterSpacing: '0.1rem',
    backgroundColor: '#6e3093',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
   },
   buttonWrapper: {
     width: '100%',
     display: 'flex',
     justifyContent: 'center',
     paddingBottom: '40px'
   }
}));

const AllPosts = ({ posts, loadMore }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headerBox}>
        <h1 className={classes.headerStyle}>News</h1>
        <ScrollDown className={classes.scrollDownIcon} />
      </Box>
      <div className={classes.cardGrid}>
        {posts.map((post, index) => {
          return <PostCard post={post} key={index} />;
        })}
      </div>
      <div className={classes.buttonWrapper}>
        <button onClick={loadMore} className={classes.loadMoreButton}>load more</button>
      </div>
    </>
  );
};

AllPosts.propTypes = {
  posts: PropTypes.array,
  loadMore: PropTypes.func,
};

export { AllPosts };
