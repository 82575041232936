import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { CalendarIcon, UserIcon } from '../../../components/common/icons';
import PageContainer from '../../../components/common/PageContainer';
import ContentInfo from '../../../components/common/ContentInfo';
import { PostsSidebar } from '../containers';
import { PostContent } from './PostContent';
import { PostImage } from './PostImage';
import { PostMetaItem } from './PostMetaItem';
import { PostMetaWrapper } from './PostMetaWrapper';
import { PostTitle } from './PostTitle';

const useStyles = makeStyles((theme) => ({
  postMetaWrapper: {
    marginBottom: 26,
    marginTop: 13,
    width: '100%',
  },
  postContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      textAlign: 'left',
    },
  },
  postTitle: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  postTextWrapper: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
    },
  },
  postContent: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  postsSidebarWrapper: {
    width: 'auto',
    maxWidth: '100%',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: '#FFFFFF',
        '-webkit-box-shadow': 'inset 0 0 6px white',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#6e3093',
        outline: '#6e3093',
      },
    },
  },
}));

const Post = ({
  data: { author, attachment, attachment_alt, content, created_at, excerpt, title },
}) => {
  const {
    postMetaWrapper,
    postContentWrapper,
    postsSidebarWrapper,
    postContent,
    postTitle,
    postTextWrapper,
  } = useStyles();

  return (
    <PageContainer alignItems={'flex-start'}>
      <PostTitle className={postTitle}>{title}</PostTitle>
      <Grid container spacing={3} className={postContentWrapper}>
        <Grid item xs={8} className={postContent}>
          <PostImage src={attachment} alt={attachment_alt} />
          <div className={postMetaWrapper}>
            <PostMetaWrapper>
              <PostMetaItem icon={<UserIcon />}>{author}</PostMetaItem>
              <PostMetaItem icon={<CalendarIcon />}>
                {new Date(created_at).toDateString()}
              </PostMetaItem>
            </PostMetaWrapper>
          </div>
          <div className={postTextWrapper}>
            <PostContent
              pageMeta={{
                description: excerpt,
                image: attachment,
                title: title,
              }}
              showImageAndTitle={false}
              hideContactInfo={true}
            >
              {content}
            </PostContent>
          </div>
        </Grid>
        <Grid item xs={4} className={postsSidebarWrapper}>
          <PostsSidebar />
        </Grid>
      </Grid>
      <ContentInfo />
    </PageContainer>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    attachment: PropTypes.string,
    attachment_alt: PropTypes.string,
    author: PropTypes.string,
    content: PropTypes.string,
    created_at: PropTypes.string,
    excerpt: PropTypes.string,
    title: PropTypes.string,
  }),
};

export { Post };
