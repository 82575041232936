import React from 'react';
import PropTypes from 'prop-types';
import { PersonOutlineOutlined } from '@material-ui/icons';

const UserIcon = ({ className }) => {
  return <PersonOutlineOutlined className={className} />;
};

UserIcon.propTypes = {
  className: PropTypes.string,
};

export { UserIcon };
