import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { withCheckImage } from '../../../HoC';
import { PostTitleBar } from './PostTitleBar';

const useStyles = makeStyles(() => ({
  img: {
    aspectRatio: '16 / 9',
    position: 'relative',
    display: 'block',
    height: 'auto',
    objectFit: 'cover',
    maxWidth: '100%',
  },
}));

const ImageSlide = withCheckImage(
  ({ title, imageUrl, alt = 'slide image', handleOnError }) => {
    const { img } = useStyles();

    return (
      <div>
        <img onError={handleOnError} className={img} src={imageUrl} alt={alt} />
        <PostTitleBar title={title} />
      </div>
    );
  }
);

ImageSlide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
};

export { ImageSlide };
