import React from 'react';
import Centerpiece from './Centerpiece';
import PageContainer from './PageContainer';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import theme from '../../theme/theme';

const NewPage = ({ icon, iconAlt = '', pageTitle, children, showImageAndTitle }) => {
  const mediaSm = useMediaQuery(theme.breakpoints.up('sm'));

  return showImageAndTitle ? (
    <PageContainer>
      <Box pb={mediaSm ? '64px' : '44px'}>
        <Centerpiece icon={icon} iconAlt={iconAlt} title={pageTitle} />
      </Box>
      {children}
    </PageContainer>
  ) : (
    <> {children}</>
  );
};

NewPage.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  iconAlt: PropTypes.string,
  children: PropTypes.any,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  metaDesc: PropTypes.string,
  pageName: PropTypes.string,
  showImageAndTitle: PropTypes.bool,
};

export default NewPage;
