import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  postsSidebarBox: {
    width: '100%',
    height: '100%',
    maxHeight: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '0 20px',
      maxHeight: '800px',
    },
  },
  postsSidebarHeader: {
    padding: '5px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '10px',
    },
  },
  postLink: {
    padding: '10px',
    color: '#7300e6',
    textDecoration: 'none',
  },
  dividerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  postsSidebarDivider: {
    margin: '10px',
    height: '5px',
    width: '50px',
    background: '#6e3093',
  },
}));

const PostsSidebar = ({ data }) => {
  const {
    postsSidebarBox,
    postLink,
    postsSidebarHeader,
    postsSidebarDivider,
    dividerWrapper,
  } = useStyles();

  return (
    <Box className={postsSidebarBox}>
      <Typography variant="h2" className={postsSidebarHeader}>
        More Posts
      </Typography>
      <div className={dividerWrapper}>
        <div className={postsSidebarDivider}></div>
      </div>
      {data.map(({ id, title }) => {
        return (
          <Typography key={id} variant="body1" className={postLink}>
            <Link to={`${id}`} style={{ textDecoration: 'none' }}>
              {title}
            </Link>
          </Typography>
        );
      })}
    </Box>
  );
};

PostsSidebar.propTypes = {
  data: PropTypes.array,
};

export { PostsSidebar };
