import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { withCheckImage } from '../../../HoC';

const useStyle = makeStyles(() => ({
  postImage: {
    maxWidth: '100%',
  },
}));

export const PostImage = withCheckImage(
  ({ alt = 'post image', handleOnError, src }) => {
    const { postImage } = useStyle();

    return <img className={postImage} onError={handleOnError} src={src} alt={alt} />;
  }
);

PostImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};
