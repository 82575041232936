import React from 'react';
import { withContentPage } from '../../../HoC';
import { CareersContent } from './CareersContent';

const CareersComponent = (props) => {
  return <CareersContent {...props} />;
};

const Careers = withContentPage(CareersComponent);

export { Careers };
