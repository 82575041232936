import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function Animations() {
  return (
    <div>
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
    </div>
  );
}
