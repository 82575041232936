import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as PrimeFmLogo } from '../images/logo.svg';
import { ReactComponent as AlignLeftIcon } from '../images/align-left.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import theme from '../theme/theme';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  navbar: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactBox: {
    position: 'absolute',
    right: '5vw',
  },
  phoneNumber: {
    fontSize: 18,
    fontWeight: 700,
  },
  mobileMenuBox: {
    position: 'absolute',
    left: '5vw',
  },
  leftIcon: {
    width: 24,
    height: 24,
  },
  iconButton: {
    backgroundColor: theme.palette.background.secondary,
    transition: 'all ease-in 300ms',
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
    },
    '&:hover svg': {
      color: 'white',
      transform: 'scale(1.15)',
    },
  },
  headsetIcon: {
    transition: 'all ease-in 300ms',
    fontSize: 24,
  },
  logo: {
    ['@media (max-width: 460px)']: {
      width: 200,
    },
    ['@media (max-width: 345px)']: {
      width: 150,
    },
  },
});

const Navbar = ({ phone = '' }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const classes = useStyles();
  const mediaSm = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <nav>
      <Menu open={menuOpen} handleClose={handleClose} />
      <Box className={classes.navbar} bgcolor="background.navbar" pt={1} pb={1}>
        {!mediaSm && (
          <Box className={classes.mobileMenuBox}>
            <IconButton onClick={handleMenuOpen}>
              <AlignLeftIcon className={classes.leftIcon} />
            </IconButton>
          </Box>
        )}
        <Link to="/">
          <PrimeFmLogo className={classes.logo} />
        </Link>
        <Box className={classes.contactBox} display="flex" alignItems="center">
          <a href={`tel:${phone.replace(/\s/g, '')}`}>
            <IconButton classes={{ root: classes.iconButton }}>
              <FontAwesomeIcon
                className={classes.headsetIcon}
                icon={faHeadset}
                color={theme.palette.icon.primary}
              />
            </IconButton>
          </a>
          {mediaSm && (
            <Box ml={2}>
              <Typography className={classes.phoneNumber}>{phone}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </nav>
  );
};

Navbar.propTypes = {
  phone: PropTypes.string,
};

export default Navbar;
