import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from '../components/common/ImagePlaceholder';

/**
 * Pass placeholder image if
 * onError function was called
 *
 * @param Component
 * @return {function(*)}
 */
function withCheckImage(Component) {
  function WithCheckImage({ ...props }) {
    const [isNeedPlaceholder, setIsNeedPlaceholder] = useState(false);

    const handleOnError = () => {
      setIsNeedPlaceholder(true);
    };

    return isNeedPlaceholder ? (
      <ImagePlaceholder style={{ maxWidth: 300 }} {...props} />
    ) : (
      <Component handleOnError={handleOnError} {...props} />
    );
  }

  WithCheckImage.displayName = `WithCheckImage(${
    Component.displayName || Component.name
  }`;

  WithCheckImage.propTypes = {
    src: PropTypes.string,
  };

  return WithCheckImage;
}

export { withCheckImage };
