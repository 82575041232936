/**
 * Convert key value paris of object to
 * FormData structure.
 *
 * @param {FormData} formData
 * @param {Object} data
 * @param {string} parentKey
 */
function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

/**
 * Convert object literal to FromData.
 *
 * @param {Object} obj
 * @return {FormData}
 */
function jsToFormData(obj) {
  const formData = new FormData();

  buildFormData(formData, obj);

  return formData;
}

export { jsToFormData };
