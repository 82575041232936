import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: 26,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));

const PostTitle = ({ children }) => {
  const { title } = useStyle();

  return <h1 className={title}>{children}</h1>;
};

PostTitle.propTypes = {
  children: PropTypes.string,
};

export { PostTitle };
