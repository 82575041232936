import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const PageSettingsContext = createContext({ pageSettings: {}, setPageSettings: {} });

const PageSettingsProvider = ({ children }) => {
  const [pageSettings, setPageSettings] = useState({});

  return (
    <PageSettingsContext.Provider value={{ pageSettings, setPageSettings }}>
      {children}
    </PageSettingsContext.Provider>
  );
};

PageSettingsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export { PageSettingsContext, PageSettingsProvider };
