import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  metaWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    '& > div': {
      marginRight: 15,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const PostMetaWrapper = ({ children = [] }) => {
  const { metaWrapper } = useStyle();

  return <div className={metaWrapper}>{children}</div>;
};

PostMetaWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export { PostMetaWrapper };
