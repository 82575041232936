import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import LinkCard from '../../main/components/LinkCard';
import { useStyles as useStyleCardGrid } from '../../main/components/CardGrid';
import { useFetchData } from '../../../hooks';
import Loading from '../../Loading';
import Error from '../../Error';
import { CategoriesWrapper } from './CategoriesWrapper';

const useStyles = makeStyles(() => ({
  cardGridFlex: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 80,
    '& > div': {
      margin: 10,
    },
  },
}));

/**
 * @typedef {{
 *   attachment: string,
 *   attachment_alt: string,
 *   category_id: number,
 *   id: number,
 *   slug: string,
 *   title: string
 * }} Page
 */

/**
 * Find pages which have current category.
 *
 * @param {number} id - category id
 * @param {Array<Page>} pages - pages.
 * @return {Array<Page> || undefined}
 */
function findPagesWithCurrentCategory(id, pages) {
  if (Array.isArray(pages)) {
    return pages.filter((page) => {
      const { category_id } = page;

      if (category_id == id) {
        return page;
      }
    });
  }
}

const CategoryPages = ({ id }) => {
  const { isLoading, data, error } = useFetchData('/pages');
  const pages = data?.pages ? findPagesWithCurrentCategory(id, data.pages) : [];
  const wrap = useMediaQuery('(max-width: 479px)');
  const { cardGrid } = useStyleCardGrid();
  const { cardGridFlex } = useStyles();

  return (
    <CategoriesWrapper>
      {isLoading && <Loading />}
      {error && <Error message="An error occurred. Please try again later." />}
      {!isLoading && !error && (
        <Box
          className={pages.length < 4 ? cardGridFlex : cardGrid}
          style={wrap ? { flexWrap: 'wrap' } : null}
        >
          {pages.map(({ attachment, attachment_alt, id, slug, title, template }) => (
            <LinkCard
              key={id}
              image={attachment}
              alt={attachment_alt}
              text={title}
              linkText="Details"
              link={`${slug}`}
              template={template}
            />
          ))}
        </Box>
      )}
    </CategoriesWrapper>
  );
};

CategoryPages.propTypes = {
  id: PropTypes.number.isRequired,
};

export { CategoryPages };
