import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  CategoryTitle: {
    marginBottom: 40,
  },
}));

const CategoryTitle = ({ title = '' }) => {
  const { CategoryTitle } = useStyles();

  return (
    <Typography align="center" className={CategoryTitle} gutterBottom variant="h2">
      {title}
    </Typography>
  );
};

CategoryTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export { CategoryTitle };
