import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useFetchData } from '../../../hooks';
import { Post as PostComponent } from '../components';
import Error from '../../../components/Error';

const Post = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useFetchData(`/posts/${id}`);

  return (
    <>
      {error && <Error message="An error occurred. Please try again later." />}
      {isLoading && <Loading />}
      {data?.post && !error && !isLoading && <PostComponent data={data.post} />}
    </>
  );
};

export { Post };
