import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  makeStyles,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  media: {
    height: 'auto',
    width: '100%'
  },
  readMoreButton: {
    color: '#c994ff',
    padding: '8px 0',
  },
  cardContent: {
    padding: '16px 0',
  },
  cardActions: {
    padding: '8px 0',
  },
  hoverEffect: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  cardButton: {
    backgroundColor: '#6e3093',
    color: '#c994ff',
    border: '1px solid rgba(201, 148, 255, 0.5)',
    borderRadius: 12,
    width: 102,
    height: 29,
    letterSpacing: '0.1rem',
    fontSize: 12,
    '& a': {
      textDecoration: 'none',
    },
  },
  cardTitle: {
    fontWeight: 'bold',
  },
});

const PostCard = ({ post: { title, excerpt, attachment, id } }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div>
        <img src={attachment} className={classes.media} alt={title} />
        {/* <CardMedia
          className={classes.media}
          image={attachment}
          title="Paella dish"
        /> */}
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.cardTitle}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            {excerpt}
          </Typography>
        </CardContent>
      </div>
      <div>
        <CardActions className={classes.cardActions}>
          <Button size="small" className={classes.cardButton}>
            <Link to={`post/${id}`}>Read More</Link>
          </Button>
        </CardActions>
      </div>
    </Card>
  );
};

PostCard.propTypes = {
  post: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  attachment: PropTypes.string,
};

export { PostCard };
