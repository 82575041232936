import React from 'react';
import { ContactDataCard } from './ContactDataCard';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ReactComponent as AdressIcon } from '../../../images/svg/adress.svg';
import { ReactComponent as PhoneIcon } from '../../../images/svg/phone.svg';
import { ReactComponent as EmailIcon } from '../../../images/svg/email.svg';

const useStyles = makeStyles((theme) => ({
  contactDataBox: {
    height: '50%',
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'row',
    ['@media(max-width: 735px)']: {
      flexDirection: 'column',
    },
  },
  icon: {
    margin: '60px 0 30px 0',
    width: '120px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '140px',
      height: 'auto',
    },
  },
  text: {
    textAlign: 'center',
    ['@media(max-width: 735px)']: {
      fontSize: '14px',
    },
  },
}));

const ContactData = ({
  settings: { phone, streetAddress, email, addressLocality, postalCode },
}) => {
  const { contactDataBox, icon, text } = useStyles();

  return (
    <Box className={contactDataBox}>
      <ContactDataCard>
        <AdressIcon className={icon} />
        <Typography variant="h4" component="p">
          OUR ADDRESS
        </Typography>
        <Typography className={text} variant="caption">
          {streetAddress}
        </Typography>
        <Typography className={text} variant="caption">
          {addressLocality}
        </Typography>
        <Typography className={text} variant="caption">
          {postalCode}
        </Typography>
      </ContactDataCard>
      <ContactDataCard>
        <PhoneIcon className={icon} />
        <Typography variant="h4" component="p">
          PHONE NUMBER
        </Typography>
        <Typography variant="caption">{phone}</Typography>
      </ContactDataCard>
      <ContactDataCard>
        <EmailIcon className={icon} />
        <Typography variant="h4" component="p">
          EMAIL ADDRESS
        </Typography>
        <Typography className={text} variant="caption">
          {email}
        </Typography>
      </ContactDataCard>
    </Box>
  );
};

ContactData.propTypes = {
  settings: PropTypes.shape({
    streetAddress: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    addressLocality: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};

export { ContactData };
