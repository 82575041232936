import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import theme from '../../theme/theme';
import PageContainer from '../common/PageContainer';
import About from './components/About';
import { SocialMediaIcons } from '../SocialMediaIcons';
import CardGrid from './components/CardGrid';
import { Categories } from '../categories';
import Reviews from './components/Reviews';
import PropTypes from 'prop-types';
import { getDomainAddress } from '../../utils';

const MainPage = ({ reviewsData, mainPageData, pageSettings }) => {
  const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const schemaLdJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'PrimeFm',
    description: pageSettings['og_desc'],
    brand: pageSettings.brand,
    openingHours: pageSettings['business_hours'],
    image: pageSettings['og_image'],
    '@id': getDomainAddress(),
    url: getDomainAddress(),
    telephone: pageSettings.phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: pageSettings.streetAddress,
      addressLocality: pageSettings.addressLocality,
      postalCode: pageSettings.postalCode,
      addressCountry: pageSettings.addressCountry,
    },
  };

  return (
    <PageContainer alignItems={largerThanMd ? 'flex-start' : 'center'}>
      <Helmet>
        <title>{pageSettings['meta_title']}</title>
        <meta name="description" content={pageSettings['meta_desc']} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={pageSettings['og_title']} />
        <meta property="og:description" content={pageSettings['og_desc']} />
        <meta property="og:url" content={`${getDomainAddress()}`} />
        <meta property="og:image" content={pageSettings['og_image']} />
        <meta
          property="business:contact_data:street_address"
          content={pageSettings.streetAddress}
        />
        <meta
          property="business:contact_data:locality"
          content={pageSettings.addressLocality}
        />
        <meta property="business:contact_data:region" content="Greater London" />
        <meta
          property="business:contact_data:postal_code"
          content={pageSettings.postalCode}
        />
        <meta
          property="business:contact_data:country_name"
          content={pageSettings.addressCountry}
        />
        <script type="application/ld+json">{JSON.stringify(schemaLdJson)}</script>
      </Helmet>
      <About pageSettings={pageSettings} />
      <CardGrid mainPageData={mainPageData} />
      <Categories />
      <Reviews reviewsData={reviewsData} />
      <SocialMediaIcons />
    </PageContainer>
  );
};

MainPage.propTypes = {
  reviewsData: PropTypes.array,
  mainPageData: PropTypes.array,
  pageSettings: PropTypes.any,
};
export default MainPage;
