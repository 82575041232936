import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  FormGroup,
  CircularProgress,
} from '@material-ui/core';
import theme from '../../../theme/theme';
import { getRestApiAddress, jsToFormData } from '../../../utils';

const useStyles = makeStyles({
  formCardBox: {
    flexBasis: 'auto',
    width: '100%',
  },
  inputsDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 30px 0',
  },
  formText: {
    marginBottom: '50px',
  },
  input: {
    margin: '10px 20px 20px 0',
  },
  inputNoAutofill: {
    '&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus':
      {
        color: '#fff !important',
        WebkitBoxShadow: '0 0 0 1000px #4E4E50 inset !important',
        WebkitTextFillColor: '#fff !important',
      },
  },

  cvInput: {
    margin: '20px 0 10px 0',
  },
  button: {
    width: '145px',
    height: '45px',
    fontSize: '12px',
    borderRadius: '12px',
    letterSpacing: '0.1rem',
    backgroundColor: '#6e3093',
    marginBottom: '50px',
    [theme.breakpoints.up('md')]: {
      marginRight: '50px',
    },
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  loading: {
    height: 'auto',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '18px',
  },
});

const CareersFormCard = () => {
  const classes = useStyles();
  const CONTACT_URL = `${getRestApiAddress()}/careers`;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [addressSecondLine, setAddressSecondLine] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [preferredHours, setPreferredHours] = useState([]);
  const [preferredAreas, setPreferredAreas] = useState([]);
  const [doYouHaveTheRightToWorkInTheUk, setRightToWork] = useState('');
  const [haveYouWorkedForPrimeCleanInThePast, setHaveYouWorked] = useState('');
  const [whatIsTheBestMethodToContactYou, setMethodToContact] = useState([]);
  const [cv, setCv] = useState(null);
  const [consent, setConsent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isChecked, setIsChecked] = useState({
    City: false,
    'West End': false,
    'North London': false,
    'South London': false,
    'East London': false,
    'West London': false,
  });

  const [hoursIsChecked, setHoursIsChecked] = useState({
    Morning: false,
    Afternoon: false,
    Evening: false,
    Night: false,
    'Any Hours': false,
  });

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [addressSecondLineError, setAddressSecondLineError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [preferredHoursError, setPreferredHoursError] = useState([]);
  const [preferredAreasError, setPreferredAreasError] = useState([]);
  const [doYouHaveTheRightToWorkInTheUkError, setRightToWorkError] = useState('');
  const [haveYouWorkedForPrimeCleanInThePastError, setHaveYouWorkedError] =
    useState('');
  const [whatIsTheBestMethodToContactYouError, setMethodToContactError] = useState(
    []
  );
  const [cvError, setCvError] = useState('');
  const [consentError, setConsentError] = useState('');

  const [message, setMessage] = useState('');

  const resetInputs = () => {
    setFirstName('');
    setLastName('');
    setAddress('');
    setAddressSecondLine('');
    setCity('');
    setState('');
    setPostalCode('');
    setCountry('');
    setPhone('');
    setEmail('');
    setPreferredHours([]);
    setPreferredAreas([]);
    setRightToWork('');
    setHaveYouWorked('');
    setMethodToContact([]);
    setCv(null);
    setConsent(false);
    setSelectAll(false);
    setIsChecked({
      City: false,
      'West End': false,
      'North London': false,
      'South London': false,
      'East London': false,
      'West London': false,
    });
    setHoursIsChecked({
      Morning: false,
      Afternoon: false,
      Evening: false,
      Night: false,
      'Any Hours': false,
    });
  };

  const toggleSelectAll = () => {
    setIsChecked({
      City: !selectAll,
      'West End': !selectAll,
      'North London': !selectAll,
      'South London': !selectAll,
      'East London': !selectAll,
      'West London': !selectAll,
    });
  };

  const isError = () => {
    return (
      firstName === '' ||
      lastName === '' ||
      address === '' ||
      addressSecondLine === '' ||
      city === '' ||
      state === '' ||
      postalCode === '' ||
      country === '' ||
      phone === '' ||
      email === '' ||
      preferredHours.length === 0 ||
      preferredAreas.length === 0 ||
      haveYouWorkedForPrimeCleanInThePast === '' ||
      doYouHaveTheRightToWorkInTheUk.length === '' ||
      whatIsTheBestMethodToContactYou.length === 0 ||
      !cv ||
      consent === false
    );
  };

  const handleSelectAll = (e) => {
    isChecked[e.target.name]
      ? setIsChecked({ ...isChecked, [e.target.name]: false })
      : setIsChecked({ ...isChecked, [e.target.name]: true });

    if (e.target.name === 'Select All') {
      if (!selectAll) {
        setSelectAll(true);
        setPreferredAreas([
          'City',
          'West End',
          'North London',
          'South London',
          'East London',
          'West London',
        ]);
        toggleSelectAll();
      } else {
        setSelectAll(false);
        setPreferredAreas([]);
        toggleSelectAll();
      }
    } else {
      preferredAreas.indexOf(e.target.name) === -1
        ? setPreferredAreas([...preferredAreas, e.target.name])
        : (preferredAreas.splice(preferredAreas.indexOf(e.target.name), 1),
          setSelectAll(false));
    }
  };

  const handlePreferredHours = (e) => {
    hoursIsChecked[e.target.name]
      ? setHoursIsChecked({ ...hoursIsChecked, [e.target.name]: false })
      : setHoursIsChecked({ ...hoursIsChecked, [e.target.name]: true });

    preferredHours.indexOf(e.target.name) === -1
      ? setPreferredHours([...preferredHours, e.target.name])
      : preferredHours.splice(preferredHours.indexOf(e.target.name), 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    firstName.length === 0
      ? setFirstNameError('First name is required')
      : setFirstNameError('');

    lastName.length === 0
      ? setLastNameError('Last name is required')
      : setLastNameError('');

    address.length === 0
      ? setAddressError('Address is required')
      : setAddressError('');

    addressSecondLine.length === 0
      ? setAddressSecondLineError('Address is required')
      : setAddressSecondLineError('');

    city.length === 0 ? setCityError('City is required') : setCityError('');

    state.length === 0 ? setStateError('State is required') : setStateError('');

    postalCode.length === 0
      ? setPostalCodeError('Postal code is required')
      : setPostalCodeError('');

    country.length === 0
      ? setCountryError('Country is required')
      : setCountryError('');

    phone.length < 6 ? setPhoneError('Phone is required') : setPhoneError('');

    !email.match(isEmail) ? setEmailError('Email is required') : setEmailError('');

    preferredHours.length === 0
      ? setPreferredHoursError('Prefered hours are required')
      : setPreferredHoursError('');

    preferredAreas.length === 0
      ? setPreferredAreasError('Prefered aeras are required')
      : setPreferredAreasError('');

    doYouHaveTheRightToWorkInTheUk.length === 0
      ? setRightToWorkError('Right to work is required')
      : setRightToWorkError('');

    haveYouWorkedForPrimeCleanInThePast.length === 0
      ? setHaveYouWorkedError('Have you worked is required')
      : setHaveYouWorkedError('');

    whatIsTheBestMethodToContactYou.length === 0
      ? setMethodToContactError('Choose contact method')
      : setMethodToContactError('');

    !cv ? setCvError('CV is required') : setCvError('');

    !consent ? setConsentError('Conset is required') : setConsentError('');

    if (!isError()) {
      setIsFetching(true);
      setMessage('');

      const formData = {
        firstName,
        lastName,
        address,
        addressSecondLine,
        city,
        state,
        postalCode,
        country,
        phone,
        email,
        preferredHours,
        preferredAreas,
        doYouHaveTheRightToWorkInTheUk,
        haveYouWorkedForPrimeCleanInThePast,
        whatIsTheBestMethodToContactYou,
        cv,
        consent,
      };

      fetch(CONTACT_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: jsToFormData(formData),
      })
        .then((res) => {
          if (res.ok) {
            setIsFetching(false);
            setMessage('Thank you! Your message has been send.');
            resetInputs();
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch((err) => {
          setIsFetching(false);
          setMessage('Error! Fail to send message.');
          resetInputs();
          console.error(err);
        });
    }
  };
  return (
    <Box className={classes.flexBasis}>
      <Typography variant="body1" className={classes.formText}>
        If you are interested in working with us, please fill out the contact form
        below and a member of staff will be in touch.
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Typography variant="body1">Name</Typography>
        <div className={classes.inputsDiv}>
          <TextField
            // required
            fullWidth
            name="firstName"
            id="careers-first-name"
            type="text"
            placeholder="First Name"
            value={firstName}
            error={firstNameError ? true : false}
            helperText={firstNameError}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
          <TextField
            // required
            fullWidth
            name="lastName"
            id="careers-last-name"
            type="text"
            placeholder="Last Name"
            value={lastName}
            error={lastNameError ? true : false}
            helperText={lastNameError}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
        </div>
        <Typography variant="body1">Adress</Typography>
        <TextField
          // required
          fullWidth
          name="address"
          id="careers-street-adress"
          type="text"
          placeholder="Street adress"
          value={address}
          error={addressError ? true : false}
          helperText={addressError}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          className={classes.input}
          inputProps={{
            className: classes.inputNoAutofill,
          }}
        />
        <TextField
          // required
          fullWidth
          name="addressSecondLine"
          id="careers-line-2-adress"
          type="text"
          placeholder="Address Line 2"
          value={addressSecondLine}
          error={addressSecondLineError ? true : false}
          helperText={addressSecondLineError}
          onChange={(e) => {
            setAddressSecondLine(e.target.value);
          }}
          className={classes.input}
          inputProps={{
            className: classes.inputNoAutofill,
          }}
        />
        <div className={classes.inputsDiv}>
          <TextField
            // required
            fullWidth
            name="city"
            id="careers-city"
            type="text"
            placeholder="City"
            value={city}
            error={cityError ? true : false}
            helperText={cityError}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
          <TextField
            // required
            fullWidth
            name="state"
            id="careers-state"
            type="text"
            placeholder="
                    State / Province / Region"
            value={state}
            error={stateError ? true : false}
            helperText={stateError}
            onChange={(e) => {
              setState(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
        </div>
        <div className={classes.inputsDiv}>
          <TextField
            // required
            fullWidth
            name="postalCode"
            id="careers-postal-code"
            type="text"
            placeholder="ZIP / Postal Code"
            value={postalCode}
            error={postalCodeError ? true : false}
            helperText={postalCodeError}
            onChange={(e) => {
              setPostalCode(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
          <TextField
            // required
            fullWidth
            name="country"
            id="careers-country"
            type="text"
            placeholder="Country"
            value={country}
            error={countryError ? true : false}
            helperText={countryError}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
        </div>
        <Typography variant="body1">Phone</Typography>
        <TextField
          // required
          fullWidth
          name="phone"
          id="careers-phone"
          type="text"
          placeholder="Phone"
          value={phone}
          error={phoneError ? true : false}
          helperText={phoneError}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          className={classes.input}
          inputProps={{
            className: classes.inputNoAutofill,
          }}
        />
        <Typography variant="body1">Email</Typography>
        <TextField
          // required
          fullWidth
          name="email"
          id="careers-phone"
          type="email"
          placeholder="Email"
          value={email}
          error={emailError ? true : false}
          helperText={emailError}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className={classes.input}
          inputProps={{
            className: classes.inputNoAutofill,
          }}
        />
        <Typography variant="body1">
          Have you worked for Prime Clean in the past?
        </Typography>
        <div className={classes.errorMessage}>
          {haveYouWorkedForPrimeCleanInThePastError}
        </div>
        <RadioGroup
          name="haveYouWorkedForPrimeCleanInThePast"
          value={haveYouWorkedForPrimeCleanInThePast}
          onChange={(e) => {
            setHaveYouWorked(e.target.value);
          }}
          className={classes.checkboxDiv}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
        <Typography variant="body1">Preferred Hours</Typography>
        <div className={classes.errorMessage}>{preferredHoursError}</div>
        <FormGroup
          className={classes.checkboxDiv}
          name="preferredHours"
          onChange={(e) => handlePreferredHours(e)}
        >
          <FormControlLabel
            control={<Checkbox name="Morning" checked={hoursIsChecked['Morning']} />}
            label="Morning"
          />
          <FormControlLabel
            control={
              <Checkbox name="Afternoon" checked={hoursIsChecked['Afternoon']} />
            }
            label="Afternoon"
          />
          <FormControlLabel
            control={<Checkbox name="Evening" checked={hoursIsChecked['Evening']} />}
            label="Evening"
          />
          <FormControlLabel
            control={<Checkbox name="Night" checked={hoursIsChecked['Night']} />}
            label="Night"
          />
          <FormControlLabel
            control={
              <Checkbox name="Any Hours" checked={hoursIsChecked['Any Hours']} />
            }
            label="Any Hours"
          />
        </FormGroup>
        <Typography variant="body1">Preferred Areas</Typography>
        <div className={classes.errorMessage}>{preferredAreasError}</div>
        <FormGroup
          name="preferredAreas"
          className={classes.checkboxDiv}
          onChange={(e) => handleSelectAll(e)}
        >
          <FormControlLabel
            control={<Checkbox name="Select All" checked={selectAll} />}
            label="Select All"
          />

          <FormControlLabel
            control={<Checkbox name="City" checked={isChecked['City']} />}
            label="City"
          />
          <FormControlLabel
            control={<Checkbox name="West End" checked={isChecked['West End']} />}
            label="West End"
          />
          <FormControlLabel
            control={
              <Checkbox name="North London" checked={isChecked['North London']} />
            }
            label="North London"
          />
          <FormControlLabel
            control={
              <Checkbox name="South London" checked={isChecked['South London']} />
            }
            label="South London"
          />
          <FormControlLabel
            control={
              <Checkbox name="East London" checked={isChecked['East London']} />
            }
            label="East London"
          />
          <FormControlLabel
            control={
              <Checkbox name="West London" checked={isChecked['West London']} />
            }
            label="West London"
          />
        </FormGroup>
        <Typography variant="body1">
          Do you have the right to work in the UK?
        </Typography>
        <div className={classes.errorMessage}>
          {doYouHaveTheRightToWorkInTheUkError}
        </div>
        <RadioGroup
          className={classes.checkboxDiv}
          onChange={(e) => {
            setRightToWork(e.target.value);
          }}
          value={doYouHaveTheRightToWorkInTheUk}
          name="doYouHaveTheRightToWorkInTheUk"
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
        <Typography variant="body1">
          What is the best method to contact you?
        </Typography>
        <div className={classes.errorMessage}>
          {whatIsTheBestMethodToContactYouError}
        </div>
        <FormGroup
          name="whatIsTheBestMethodToContactYou"
          onChange={(e) => {
            whatIsTheBestMethodToContactYou.indexOf(e.target.name) === -1
              ? setMethodToContact([
                  ...whatIsTheBestMethodToContactYou,
                  e.target.name,
                ])
              : whatIsTheBestMethodToContactYou.splice(
                  whatIsTheBestMethodToContactYou.indexOf(e.target.name),
                  1
                );
          }}
          className={classes.checkboxDiv}
        >
          <FormControlLabel control={<Checkbox name="Phone" />} label="Phone" />
          <FormControlLabel control={<Checkbox name="E-mail" />} label="E-mail" />
        </FormGroup>
        <div className={classes.checkboxDiv}>
          <label>Upload CV</label>
          <div className={classes.errorMessage}>{cvError}</div>
          <input
            type="file"
            onChange={(e) => {
              setCv(e.target.files[0]);
            }}
            id="cv"
            name="cv"
            accept=".pdf"
            className={classes.cvInput}
          />
          <p>Please upload your CV</p>
        </div>
        <Typography variant="body1">Consent</Typography>
        <div className={classes.errorMessage}>{consentError}</div>
        <div className={classes.checkboxDiv}>
          <FormControlLabel
            onChange={(e) => setConsent(e.target.checked)}
            value={consent}
            control={<Checkbox name="conset" checked={consent} />}
            label="I agree for my application to be kept on file for up to 3 months incase any suitable vacancies become available"
          />
        </div>
        <div className={classes.submit}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            Submit
          </Button>
          {isFetching ? (
            <CircularProgress />
          ) : (
            <Typography variant="body1">{message}</Typography>
          )}
        </div>
      </form>
    </Box>
  );
};
export { CareersFormCard };
