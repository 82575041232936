import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Error = ({ message }) => {
  return (
    <Box
      bgcolor="background.main"
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <div>{message}</div>
    </Box>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
