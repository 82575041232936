import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Loading = () => {
  return (
    <Box
      bgcolor="background.main"
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
