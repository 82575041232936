import React from 'react';
import PropTypes from 'prop-types';
import { CalendarTodayOutlined } from '@material-ui/icons';

const CalendarIcon = ({ className }) => {
  return <CalendarTodayOutlined className={className} />;
};

CalendarIcon.propTypes = {
  className: PropTypes.string,
};

export { CalendarIcon };
