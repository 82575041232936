import React, { useState } from 'react';
import { Box, Button, makeStyles, useMediaQuery } from '@material-ui/core';
import MiniTitle from '../../common/MiniTitle';
import ReviewCard from './ReviewCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import theme from '../../../theme/theme';
import PropTypes from 'prop-types';
import AddReview from './AddReview';
import SwiperCore, { Autoplay } from 'swiper';

const useStyles = makeStyles({
  sliderBox: {
    width: '100%',
    height: 20,
    boxSizing: 'border-box',
  },
  swiperWrapper: {
    overflow: 'hidden',
  },
  swiperSlide: {
    width: 234,
    [theme.breakpoints.up('md')]: {
      width: 386,
    },
  },
  buttonBox: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  button: {
    borderRadius: 12,
    background: '#6e3093',
    height: 29,
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: '0.1rem',
    position: 'relative',
    top: '440px',
  },
});

SwiperCore.use([Autoplay]);

const Reviews = ({ reviewsData }) => {
  const [formOpen, setFormOpen] = useState(false);
  const handleOpen = () => {
    setFormOpen(true);
  };
  const handleClose = () => {
    setFormOpen(false);
  };
  const largerThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const largerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const largerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { sliderBox, swiperSlide } = useStyles();
  const classes = useStyles();

  return (
    <Box width="100%" mb={60}>
      <MiniTitle smallText="Our clients" largeText="Reviews" />
      <Box className={sliderBox}>
        {reviewsData && (
          <Swiper
            speed={600}
            spaceBetween={20}
            slidesPerView={
              largerThanXl
                ? 3
                : largerThanLg
                ? 2.25
                : largerThanMd
                ? 2
                : largerThanSm
                ? 1.75
                : 1.15
            }
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {reviewsData
              .filter((item) => item.showOnMain)
              .map(({ id, name, position, text }, index) => (
                <SwiperSlide key={id} className={swiperSlide}>
                  <ReviewCard
                    index={index}
                    name={name}
                    position={position}
                    review={text}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        className={classes.buttonBox}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          classes={{ root: classes.button }}
        >
          write a review
        </Button>
      </Box>
      <AddReview open={formOpen} handleClose={handleClose} />
    </Box>
  );
};

Reviews.propTypes = {
  reviewsData: PropTypes.array,
};

export default Reviews;
