import React from 'react';
import { useFetchData } from '../../../hooks';
import { CategoryPages } from './CategoryPages';
import { CategoryTitle } from './CategoryTitle';
import { CategoriesWrapper } from './CategoriesWrapper';
import Error from '../../Error';
import Loading from '../../Loading';

/**
 * Sort category by title.
 *
 * @param {[{title: string}]} categories
 */
function sortCategory(categories) {
  if (Array.isArray(categories)) {
    return Array.from(
      categories.sort((a, b) => (a.title > b.title ? 1 : -1)).reverse()
    );
  }

  return [];
}

const Categories = () => {
  const { isLoading, data, error } = useFetchData('/categories');

  return (
    <CategoriesWrapper>
      {isLoading && <Loading />}
      {error && <Error message="An error occurred. Please try again later." />}
      {!isLoading &&
        !error &&
        sortCategory(data?.categories).map(({ id, title }) => (
          <CategoriesWrapper key={id}>
            <CategoryTitle title={title} />
            <CategoryPages id={id} />
          </CategoriesWrapper>
        ))}
    </CategoriesWrapper>
  );
};

export { Categories };
