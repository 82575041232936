import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import LinkCard from './LinkCard';
import theme from '../../../theme/theme';
import PropTypes from 'prop-types';

export const useStyles = makeStyles({
  cardGrid: {
    paddingBottom: 80,
    display: 'grid',
    gridTemplate: '1fr /1fr',
    gridGap: '30px 20px',
    width: 'initial',
    [theme.breakpoints.up('sm')]: {
      gridTemplateRows: 'auto',
      gridTemplate: '1fr 1fr /1fr 1fr',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'auto',
      gridTemplate: '1fr 1fr /1fr 1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateRows: 'auto',
      gridTemplate: '1fr 1fr /1fr 1fr 1fr 1fr',
    },
  },
  cardGridFlex: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 80,
    '& > div': {
      margin: 10,
    },
    width: '100%',
  },
});

const CardGrid = ({ mainPageData }) => {
  const { cardGrid, cardGridFlex } = useStyles();
  const wrap = useMediaQuery('(max-width: 479px)');
  const pages = mainPageData.filter(({ category_id }) => {
    return !category_id;
  });

  return (
    <Box
      className={pages.length < 4 ? cardGridFlex : cardGrid}
      style={wrap ? { flexWrap: 'wrap' } : null}
    >
      {pages.map(({ id, title, attachment, attachment_alt, slug, template }) => {
        return (
          <LinkCard
            image={attachment}
            alt={attachment_alt}
            text={title}
            link={slug}
            key={id}
            template={template}
          />
        );
      })}
    </Box>
  );
};

CardGrid.propTypes = {
  mainPageData: PropTypes.array,
};

export default CardGrid;
