import React, { useState, useEffect } from 'react';
import Error from '../../../components/Error';
import Loading from '../../../components/Loading';
import { useFetchData } from '../../../hooks';
import { AllPosts as AllPostsComponent } from '../components';

const PER_PAGE = 3;

const AllPosts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const { isLoading, data, error } = useFetchData(
    `/posts/paginate?page=${currentPage}&perpage=${PER_PAGE}`
  );

  useEffect(() => {
    if (data && !error) {
      const newPosts = [];
      posts.concat(data.posts.data).forEach((post) => {
        const index = newPosts.findIndex(({ id }) => {
          return post.id === id;
        });

        if (index === -1) {
          newPosts.push(post);
        }
      });

      setPosts(newPosts);
    }
  }, [data]);

  const handleLoadMore = () => {
    if (currentPage < data.posts.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      {error && <Error message="An error occurred. Please try again later." />}
      {isLoading && <Loading />}
      {!isLoading && !error && (
        <AllPostsComponent posts={posts} loadMore={handleLoadMore} />
      )}
    </>
  );
};

export { AllPosts };
