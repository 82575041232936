import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  metaItemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    marginRight: 5,
    '& svg': {
      height: 25,
      verticalAlign: 'sub',
      width: 25,
    },
  },
}));

const PostMetaItem = ({ icon, children }) => {
  const { iconWrapper, metaItemWrapper } = useStyles();

  return (
    <div className={metaItemWrapper}>
      <div className={iconWrapper}>{icon}</div>
      <div>{children}</div>
    </div>
  );
};

PostMetaItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.string,
};

export { PostMetaItem };
