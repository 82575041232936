import React from 'react';
import { Form } from './Form';
import StarryTitle from '../../../components/common/StarryTitle';
import { makeStyles, Box } from '@material-ui/core';
import theme from '../../../theme/theme';

const useStyles = makeStyles({
  contactFormBox: {
    height: '40%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  text: {
    padding: '20px',
  },
});

const ContactForm = () => {
  const classes = useStyles();
  return (
    <Box className={classes.contactFormBox}>
      <StarryTitle text="GET IN TOUCH" />
      <Form />
    </Box>
  );
};

export { ContactForm };
