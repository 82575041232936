import React from 'react';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import BlueCard from './components/BlueCard';
import theme from '../../theme/theme';
import NewPage from '../common/NewPage';
import Helpline from '../common/Helpline';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { getDomainAddress } from '../../utils';
import { SocialMediaIcons } from '../SocialMediaIcons';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

function transformNode(node, index) {
  const { attribs, type, name, children } = node;

  if (
    attribs &&
    attribs.style &&
    (name === 'span' ||
      name === 'a' ||
      name === 'p' ||
      name === 'li' ||
      name === 'ul' ||
      name === 'h1' ||
      name === 'h2' ||
      name === 'h3' ||
      name === 'h4' ||
      name === 'h5' ||
      name === 'h6')
  ) {
    delete attribs.style;
  }

  if (name === 'img') {
    let imgStyles = 'max-width: 100%; height: auto;';
    const style = attribs.style;

    if (style) {
      imgStyles = `${imgStyles} ${style}`;
      attribs.style = imgStyles;
    } else {
      attribs.style = imgStyles;
    }

    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && children.length === 1) {
    if (children[0].data === ' ') {
      return null;
    }

    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && name !== 'br' && children.length === 0) {
    return null;
  }

  if (name === 'p' && node.children[0] && node.children[0].name === 'ul') {
    const transformedLis = node.children[0].children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul>{transformedLis}</ul>;
  }

  if (name === 'ul' && node.children[0]) {
    const transformedLis = node.children.map((li, index) => (
      <li key={index + li.children[0].data}>{li.children[0].data}</li>
    ));
    return <ul key={index + name}>{transformedLis}</ul>;
  }

  if (type === 'tag' && name === 'p') {
    return convertNodeToElement(node, index, transformNode);
  }

  if (type === 'tag' && name === 'span') {
    return convertNodeToElement(node, index, transformNode);
  }

  if (name === 'meta') {
    return null;
  }

  if (type === 'tag' && (name === 'h1' || name === 'h2')) {
    return (
      <Box key={index + name} pt={4} pb={4}>
        <h2>{node.children[0].data}</h2>
      </Box>
    );
  }
}

const useStyles = makeStyles({
  content: {
    '& *': {
      wordBreak: 'break-word',
    },
    '& p, span, li': {
      fontSize: '1.5rem',
    },
    '& ul': {
      paddingLeft: 50,
    },
  },
  socialIconsWrapper: {
    margin: '20px 0',
    [theme.breakpoints.up('md')]: {
      margin: '80px 0',
    },
  },
});

const Plans = ({
  pageSettings,
  plansData,
  icon,
  handleOpen,
  updateData,
  content,
}) => {
  const classes = useStyles();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const schemaLdJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'PrimeFm',
    description: plansData['og_desc'],
    brand: pageSettings.brand,
    openingHours: pageSettings['business_hours'],
    image: icon,
    '@id': getDomainAddress(),
    url: getDomainAddress(),
    telephone: pageSettings.phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: pageSettings.streetAddress,
      addressLocality: pageSettings.addressLocality,
      postalCode: pageSettings.postalCode,
      addressCountry: pageSettings.addressCountry,
    },
  };

  const blueCardProps = {
    email: pageSettings.email,
    phone: pageSettings.phone,
    helpline: plansData.helpline,
    handleOpen: handleOpen,
    updateData: updateData,
  };

  return (
    <NewPage
      icon={icon}
      title="COVID19"
      pageTitle="COVID19"
      metaDesc="meta description"
      noPadding
    >
      <Helmet>
        <title>{pageSettings['site_title']}</title>
        <meta name="description" content={plansData['meta_desc']} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={plansData['og_title']} />
        <meta property="og:description" content={plansData['og_desc']} />
        <meta property="og:url" content={`${getDomainAddress()}/covid19`} />
        <meta property="og:image" content={icon} />
        <meta
          property="business:contact_data:street_address"
          content={pageSettings.streetAddress}
        />
        <meta
          property="business:contact_data:locality"
          content={pageSettings.addressLocality}
        />
        <meta property="business:contact_data:region" content="Greater London" />
        <meta
          property="business:contact_data:postal_code"
          content={pageSettings.postalCode}
        />
        <meta
          property="business:contact_data:country_name"
          content={pageSettings.addressCountry}
        />
        <script type="application/ld+json">{JSON.stringify(schemaLdJson)}</script>
      </Helmet>
      <Container
        maxWidth="xl"
        component={Box}
        pb={isLargerThanMd ? 10 : '60px'}
        pt={isLargerThanMd ? '80px' : '60px'}
      >
        <Typography variant="body1">{plansData.text}</Typography>
      </Container>
      <Box
        display="flex"
        flexWrap="wrap"
        width="100%"
        alignItems="center"
        mb={10}
        justifyContent="center"
      >
        <BlueCard
          content={plansData.section1}
          title={plansData['s1_title']}
          subtitle={plansData['s1_sub_title']}
          modalContent={plansData['modal_1']}
          modalBottomText={plansData['bottom_text_1']}
          id="1"
          {...blueCardProps}
        />
        <BlueCard
          content={plansData.section2}
          title={plansData['s2_title']}
          subtitle={plansData['s2_sub_title']}
          modalContent={plansData['modal_2']}
          modalBottomText={plansData['bottom_text_2']}
          id="2"
          {...blueCardProps}
        />
        <BlueCard
          content={plansData.section3}
          title={plansData['s3_title']}
          subtitle={plansData['s3_sub_title']}
          modalContent={plansData['modal_3']}
          modalBottomText={plansData['bottom_text_3']}
          id="3"
          {...blueCardProps}
        />
      </Box>
      <Box className={classes.content} pt={2} pb={2}>
        {ReactHtmlParser(content, { transform: transformNode })}
      </Box>
      <Helpline email={pageSettings.email} phone={pageSettings.phone} />
      <div className={classes.socialIconsWrapper}>
        <SocialMediaIcons />
      </div>
    </NewPage>
  );
};

Plans.propTypes = {
  plansData: PropTypes.object,
  content: PropTypes.object,
  icon: PropTypes.string,
  pageSettings: PropTypes.object,
  handleOpen: PropTypes.func,
  updateData: PropTypes.func,
};

export default Plans;
