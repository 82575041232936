import React, { useContext } from 'react';
import { PageSettingsContext } from '../../contexts';
import Helpline from './Helpline';
import { SocialMediaIcons } from '../SocialMediaIcons';

const ContentInfo = () => {
  const {
    pageSettings: { email, phone },
  } = useContext(PageSettingsContext);

  return (
    <>
      <Helpline email={email} phone={phone} />
      <SocialMediaIcons />
    </>
  );
};

export default ContentInfo;
