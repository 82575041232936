import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const BlogContext = createContext({ blogRoute: '', setBlogRoute: () => {} });

const BlogContextProvider = ({ children }) => {
  const [blogRoute, setBlogRoute] = useState('/posts');

  return (
    <BlogContext.Provider value={{ blogRoute, setBlogRoute }}>
      {children}
    </BlogContext.Provider>
  );
};

BlogContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export { BlogContextProvider, BlogContext };
