import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { makeStyles } from '@material-ui/core';
import { Slide } from './Slide';
import 'swiper/swiper.scss';

const useStyle = makeStyles((theme) => ({
  sliderWrapper: {
    aspectRatio: '16 / 9',
    height: '100%',
    '@media(max-width: 670px)': {
      marginBottom: '40px',
    },
  },
  swiper: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 24,
    padding: 15,
  },
}));

const PostsSlider = ({ data }) => {
  const { sliderWrapper, swiper } = useStyle();

  return (
    <div className={sliderWrapper}>
      <Swiper
        autoHeight
        autoplay
        slidesPerView="auto"
        className={swiper}
        spaceBetween={20}
      >
        {data.map(({ attachment_alt, attachmentSlider, id, title }) => {
          return (
            <SwiperSlide key={id}>
              <Slide
                id={id}
                imageUrl={attachmentSlider}
                alt={attachment_alt}
                title={title}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

PostsSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentSlider: PropTypes.string,
      author: PropTypes.string,
      content: PropTypes.string,
      created_at: PropTypes.string,
      excerpt: PropTypes.string,
      id: PropTypes.number,
      is_publish: PropTypes.oneOf([0, 1]),
      title: PropTypes.string,
      updated_at: PropTypes.string,
    })
  ).isRequired,
};

export { PostsSlider };
