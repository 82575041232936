import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  Container,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MapIcon } from '../../images/map-marked.svg';
import { ReactComponent as PhoneIcon } from '../../images/mobile-android.svg';
import { ReactComponent as EnvelopeIcon } from '../../images/envelope.svg';
import { ReactComponent as PeopleSvg } from '../../images/people.svg';
import theme from '../../theme/theme';
import { ReactComponent as InstagramIcon } from './icons/instagram.svg';
import { ReactComponent as BookIcon } from './icons/book.svg';

const useStyles = makeStyles({
  list: {
    width: '100%',
  },
  listIcon: {
    justifyContent: 'center',
    minWidth: 50,
    width: 50,
    height: 50,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30,
      minWidth: 30,
    },
  },
  listItem: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  footerText: {
    paddingLeft: 10,
    '& p': {
      fontSize: 21,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    '& a': {
      textDecoration: 'none',
    },
  },
});

const Footer = ({ pageSettings }) => {
  const classes = useStyles();
  const mediaSm = useMediaQuery(theme.breakpoints.down('md'));
  const footerData = [
    {
      icon: <MapIcon />,
      text: (
        <Box className={classes.addressText}>
          <a
            href="https://g.page/PrimeCleanLTD?share"
            target="_blank"
            rel="noreferrer"
          >
            <Typography>{pageSettings && pageSettings.streetAddress}</Typography>
            <Typography>{pageSettings && pageSettings.addressLocality},</Typography>
            <Typography>{pageSettings && pageSettings.postalCode}</Typography>
          </a>
        </Box>
      ),
      id: 123423,
    },
    {
      icon: <InstagramIcon />,
      text: (
        <Typography color="textPrimary">
          <a
            rel="noreferrer"
            href="https://www.instagram.com/primeclean_com/"
            target="_blank"
          >
            Stories
          </a>
        </Typography>
      ),
      id: 8882,
    },
    {
      icon: <BookIcon />,
      text: (
        <Typography color="textPrimary">
          <a rel="noreferrer" href="/prime-clean-brochure.pdf" target="_blank">
            Services brochure
          </a>
        </Typography>
      ),
      id: 8883,
    },
    {
      icon: <PhoneIcon />,
      text: (
        <Typography color="textPrimary">
          <a href={`tel:${pageSettings && pageSettings.phone}`}>
            {pageSettings && pageSettings.phone}
          </a>
        </Typography>
      ),
      id: 255521,
    },
    {
      icon: <EnvelopeIcon />,
      text: (
        <Typography color="textPrimary">
          <a href={`mailto:${pageSettings && pageSettings.email}`}>
            {pageSettings && pageSettings.email}
          </a>
        </Typography>
      ),
      id: 2342352346,
    },
  ];
  return (
    <Box width="100%" display="flex" bgcolor="background.navbar" pt={6}>
      <Container maxWidth="xl" className={classes.footerContainer}>
        <Box pt={1} pb={1}>
          <Typography align="center" variant="h3">
            If you have any questions, please contact us
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={!mediaSm ? 'row' : 'column'}
        >
          <List className={classes.list}>
            {footerData.map(({ icon, text, id }) => (
              <ListItem disableGutters key={id} classes={{ root: classes.listItem }}>
                <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
                <ListItemText className={classes.footerText} primary={text} />
              </ListItem>
            ))}
          </List>

          <PeopleSvg />
        </Box>
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  pageSettings: PropTypes.any,
};

export default Footer;
