import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import theme from '../theme/theme';
import { ReactComponent as Instagram } from '../images/svg/instagram.svg';
import { ReactComponent as Facebook } from '../images/svg/facebook.svg';
import { ReactComponent as Linkedin } from '../images/svg/linkedin.svg';
import { ReactComponent as Youtube } from '../images/svg/youtube.svg';
import { ReactComponent as Twitter } from '../images/svg/twitter.svg';

const useStyles = makeStyles({
  socialIconBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: '50px',
    },
  },
  icon: {
    margin: '0 5px',
    width: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '50px',
      margin: '0 10px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '90px',
      margin: '0 20px',
    },
  },
});

const SocialMediaIcons = () => {
  const classes = useStyles();
  return (
    <Box className={classes.socialIconBox}>
      <a
        href="https://www.instagram.com/primeclean_com/"
        target="_blank"
        rel="noreferrer"
        className={classes.socialIcon}
      >
        <Instagram className={classes.icon} />
      </a>
      <a
        href="https://www.facebook.com/PrimeCleanCom/"
        target="_blank"
        rel="noreferrer"
        className={classes.socialIcon}
      >
        <Facebook className={classes.icon} />
      </a>
      <a
        href="https://www.linkedin.com/company/prime-clean-limited"
        target="_blank"
        rel="noreferrer"
        className={classes.socialIcon}
      >
        <Linkedin className={classes.icon} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCYYoiRXy3ZgX3Gg282D3kJA/featured"
        target="_blank"
        rel="noreferrer"
        className={classes.socialIcon}
      >
        <Youtube className={classes.icon} />
      </a>
      <a
        href="https://twitter.com/PrimeCleanCom1"
        target="_blank"
        rel="noreferrer"
        className={classes.socialIcon}
      >
        <Twitter className={classes.icon} />
      </a>
    </Box>
  );
};

export { SocialMediaIcons };
