import React, { useState } from 'react';
import {
  Box,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { getRestApiAddress } from '../../../utils';
import theme from '../../../theme/theme';

const useStyles = makeStyles({
  formBox: {
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
  },
  inputsDiv: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  input: {
    flexGrow: 1,
    padding: '20px 10px',
    width: '100%',
  },
  inputNoAutofill: {
    '&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus':
      {
        color: '#fff !important',
        WebkitBoxShadow: '0 0 0 1000px #4E4E50 inset !important',
        WebkitTextFillColor: '#fff !important',
      },
  },
  button: {
    width: '145px',
    height: '45px',
    fontSize: '12px',
    borderRadius: '12px',
    letterSpacing: '0.1rem',
    backgroundColor: '#6e3093',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
      marginRight: '100px',
    },
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
});

const Form = () => {
  const classes = useStyles();
  const CONTACT_URL = `${getRestApiAddress()}/contact`;
  const isEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [text, setText] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const isError = () => {
    return (
      name === '' || isEmail.test(email) === false || phone === '' || message === ''
    );
  };

  const resetInputs = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFetching(true);

    if (name === '') {
      setNameError('Incorrect Entry');
      setIsFetching(false);
    } else {
      setNameError('');
    }

    if (!isEmail.test(email)) {
      setEmailError('Incorrect email');
      setIsFetching(false);
    } else {
      setEmailError('');
    }

    if (phone === '') {
      setPhoneError('Please write phone number');
      setIsFetching(false);
    } else {
      setPhoneError('');
    }

    if (message === '') {
      setMessageError('Please write a message');
      setIsFetching(false);
    } else {
      setMessageError('');
    }

    if (!isError()) {
      fetch(CONTACT_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          fullName: name,
          email: email,
          phone: phone,
          yourMessage: message,
        }),
      })
        .then((res) => {
          if (res.ok) {
            setIsFetching(false);
            resetInputs();
            setText(
              'Thank you for sending your message, we will reply to it as soon as possible!'
            );
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch((err) => {
          setIsFetching(false);
          resetInputs();
          setText('Error! Your message was not submitted.');
          console.error(err);
        });
    }
  };

  return (
    <Box className={classes.formBox}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.inputsDiv}>
          <TextField
            // required
            id="contact-name"
            type="text"
            className={classes.input}
            error={!!nameError}
            placeholder="Full name"
            helperText={nameError}
            value={name}
            onChange={handleNameChange}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
          <TextField
            // required
            id="contact-email"
            type="text"
            error={!!emailError}
            placeholder="Email"
            helperText={emailError}
            value={email}
            onChange={handleEmailChange}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
            className={classes.input}
          />
        </div>
        <div className={classes.inputsDiv}>
          <TextField
            // required
            id="contact-nphone"
            type="text"
            error={!!phoneError}
            placeholder="Phone"
            helperText={phoneError}
            value={phone}
            onChange={handlePhoneChange}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
            className={classes.input}
          />
          <TextField
            // required
            id="contact-message"
            type="text"
            error={!!messageError}
            placeholder="Your message"
            helperText={messageError}
            value={message}
            onChange={handleMessageChange}
            className={classes.input}
            inputProps={{
              className: classes.inputNoAutofill,
            }}
          />
        </div>
        <div className={classes.submit}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            Submit
          </Button>
          {isFetching ? <CircularProgress /> : <div>{text}</div>}
        </div>
      </form>
    </Box>
  );
};

export { Form };
