import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { CareersFormCard } from './CareersFormCard';
import theme from '../../../theme/theme';

const useStyles = makeStyles({
  container: {
    margin: '100px 0',
    padding: 0,
  },
  contentContainer: {
    display: 'block',
  },
  offer: {
    height: 'auto',
    maxWidth: '100%',
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  form: {
    margin: '100px 0',
    background: '#4E4E50',
    maxWidth: '100%',
    height: 'auto',
    padding: '20px',
    borderRadius: '24px',
  },
  ArrowIcon: {
    color: '#fff',
  },
});

const CareersContent = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xg" className={classes.form}>
      <CareersFormCard />
    </Container>
  );
};

export { CareersContent };
