import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  contactCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    alignItems: 'center',
    padding: '0 20px',
  },
});

const ContactDataCard = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.contactCard}>{children}</Box>;
};

ContactDataCard.propTypes = {
  children: PropTypes.any,
};

export { ContactDataCard };
