import { useCallback, useState, useEffect } from 'react';
import { getRestApiAddress } from '../utils';

/**
 * Cache for endpoint data
 * @type {Object<string, any>}
 */
const cache = {};

/**
 * Hook for fetch data
 *
 * @param {string} endpoint
 */

export function useFetchData(endpoint) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cache[endpoint]) {
      fetch(`${getRestApiAddress()}${endpoint}`)
        .then((data) => data.json())
        .then((data) => {
          if (data) {
            setData(data);
            cache[endpoint] = data;
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setData([]);
          setIsLoading(false);
          setError(err);
        });
    } else {
      if (cache[endpoint]) {
        setIsLoading(false);
        setData(cache[endpoint]);
      }
    }
  }, [endpoint]);

  return { data, isLoading, error };
}
