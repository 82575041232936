import React from 'react';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import { useFetchData } from '../../../hooks';
import { PostsSidebar as PostsSidebarComponent } from '../components';

const PostsSidebar = () => {
  const { data, isLoading, error } = useFetchData(`/posts`);

  return (
    <>
      {error && <Error message="An error occurred. Please try again later." />}
      {isLoading && <Loading />}
      {data?.posts && !error && !isLoading && (
        <PostsSidebarComponent data={data['posts']} />
      )}
    </>
  );
};

export { PostsSidebar };
