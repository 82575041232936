import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BlogContext } from '../../../contexts';
import { Link } from 'react-router-dom';
import { ImageSlide } from './ImageSlide';



const Slide = ({ imageUrl, alt, title, id }) => {
  const { blogRoute } = useContext(BlogContext);

  return (
    <div>
      <Link to={`/post/${id}`}>
        <ImageSlide imageUrl={imageUrl} alt={alt} title={title} />
      </Link>
    </div>
  );
};

Slide.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
};

export { Slide };
