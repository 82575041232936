import React from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  container: {
    margin: 0,
    maxWidth: '100%',
  },
}));

const CategoriesWrapper = ({ children = null }) => {
  const { container } = useStyle();

  return (
    <Container className={container} disableGutters={true}>
      {children}
    </Container>
  );
};

CategoriesWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { CategoriesWrapper };
