import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as BrochureIcon } from './icons/bROCHURE.svg';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  brochureLink: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 35,
    marginBottom: 35,
  },
  brochureIcon: {
    width: 60,
    height: 60,
    marginRight: 15,
  },
}));

const Brochure = () => {
  const { brochureLink, brochureIcon } = useStyles();

  return (
    <a
      className={brochureLink}
      rel="noreferrer"
      href="/prime-clean-brochure.pdf"
      target="_blank"
    >
      <BrochureIcon className={brochureIcon} />
      <Typography>Services Brochure</Typography>
    </a>
  );
};

export default Brochure;
