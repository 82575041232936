import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ContentPage from '../components/common/ContentPage';
import { PageSettingsContext } from '../contexts';

/**
 * Wrap component with ContentPage component and inject
 * necessary data, generate og tags.
 *
 * @param Component
 * @return {function({children?: *, pageMeta: *, icon?: *, showImageAndTitle?: *})}
 */
function withContentPage(Component) {
  function WithContentPage({
    children,
    pageMeta,
    showImageAndTitle = true,
    hideContactInfo = false,
  }) {
    const { pageSettings } = useContext(PageSettingsContext);
    const { description, title, icon, iconAlt } = pageMeta;

    return (
      <ContentPage
        pageSettings={pageSettings}
        content={{ content: children, metaDescription: description, title }}
        icon={icon}
        iconAlt={iconAlt}
        showImageAndTitle={showImageAndTitle}
        hideContactInfo={hideContactInfo}
      >
        <Component pageSettings={pageSettings} />
      </ContentPage>
    );
  }

  WithContentPage.displayName = `WithContentPage(${
    Component.displayName || Component.name
  }`;

  WithContentPage.propTypes = {
    hideContactInfo: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    pageMeta: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
      iconAlt: PropTypes.string,
    }),
    showImageAndTitle: PropTypes.bool,
  };

  return WithContentPage;
}

export { withContentPage };
