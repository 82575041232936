import React from 'react';
import { useFetchData } from '../../../hooks';
import { PostsSlider as PostSliderComponents } from '../components';
import ImageSkeletonPlaceholder from '../components/ImageSkeletonPlaceholder';
import Error from '../../Error';

const PostsSlider = () => {
  const { data, isLoading, error } = useFetchData('/posts/latest/5');

  return (
    <>
      {error && <Error message="An error occurred. Please try again later." />}
      {isLoading && <ImageSkeletonPlaceholder />}
      {data?.posts.length > 0 && !isLoading && !error && (
        <PostSliderComponents data={data.posts} />
      )}
    </>
  );
};

export { PostsSlider };
