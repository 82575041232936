import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import theme from '../../../theme/theme';
import { ReactComponent as ArrowRight } from '../../../images/long-arrow-right.svg';

const useStyles = makeStyles({
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    [theme.breakpoints.up('smallPhone')]: {
      padding: 20,
      paddingLeft: 45,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 30,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  gridCard: {
    borderRadius: 24,
    position: 'relative',
    background: theme.palette.background.secondary,
    width: '100%',
  },
  imageBox: {
    '& img': {
      width: 80,
      height: 80,
      [theme.breakpoints.up('sm')]: {
        width: 140,
        height: 140,
      },
    },
  },
  cardText: {
    paddingLeft: 20,
    textAlign: 'left',
    '& h3': {
      fontSize: 18,
    },
    [theme.breakpoints.up('smallPhone')]: {
      paddingLeft: 30,
      '& h3': {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      paddingLeft: 0,
    },
  },
  buttonLink: {
    textDecoration: 'none',
  },
  cardButton: {
    backgroundColor: '#6e3093',
    borderRadius: 12,
    width: 102,
    height: 29,
    letterSpacing: '0.1rem',
    fontSize: 12,
  },
  linkArrow: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  mobileLink: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
  },
});

const LinkCard = ({
  alt,
  image,
  text,
  link,
  linkText = 'details',
  template = '',
}) => {
  const classes = useStyles();
  const mediaSm = useMediaQuery(theme.breakpoints.up('sm'));
  const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isExternal = template === 'brochure' || /^(https|http)?:\/\//.test(link);

  return (
    <Box
      className={classes.gridCard}
      height={mediaSm ? 355 : 120}
      maxWidth={largerThanMd ? 285 : undefined}
    >
      <Box className={classes.cardContent}>
        <Box className={classes.imageBox}>
          <img src={image} alt={alt} />
        </Box>
        <Box pb={mediaSm ? 2 : 0} className={classes.cardText}>
          <Typography variant="h3">{text}</Typography>
        </Box>
        {isExternal ? (
          <a
            href={template === 'brochure' ? `/prime-clean-brochure.pdf` : link}
            className={classes.buttonLink}
            target="_blank"
            rel="noreferrer"
          >
            {mediaSm ? (
              <Button
                color="primary"
                variant="outlined"
                className={classes.cardButton}
              >
                {linkText}
              </Button>
            ) : (
              <Box className={classes.mobileLink}></Box>
            )}
          </a>
        ) : (
          <Link to={`/${link}`} className={classes.buttonLink}>
            {mediaSm ? (
              <Button
                color="primary"
                variant="outlined"
                className={classes.cardButton}
              >
                {linkText}
              </Button>
            ) : (
              <Box className={classes.mobileLink}></Box>
            )}
          </Link>
        )}
        {!mediaSm && (
          <Box className={classes.linkArrow}>
            <ArrowRight />
          </Box>
        )}
      </Box>
    </Box>
  );
};

LinkCard.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  isExternal: PropTypes.bool,
  template: PropTypes.string,
};

export default LinkCard;
