import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Helpline = ({ email, phone }) => {
  return (
    <Typography variant="body1" component={Box} pt={2} style={{ width: '100%' }}>
      If you would like to know more, please call{' '}
      <a href={`tel:${phone}`}>{phone}</a> or email{' '}
      <a href={`mailto:${email}`}>{email}</a> for a quote.
    </Typography>
  );
};

Helpline.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default Helpline;
