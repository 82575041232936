require('dotenv').config();

/**
 * Get domain address for rest api.
 *
 * @return {string}
 */
export function getDomainAddress() {
  return process.env.REACT_APP_DOMAIN;
}
